import { FaOrcid } from 'react-icons/fa';

import styles from './ListUsers.module.css';

const ORCID_PATTERN = /(?:[\dA-Z]{4}-){3}[\dA-Z]{4}/u;

interface Props {
  listUsers: string;
}

function Users(props: Props) {
  // The list of users should be formatted as
  // "{first-name} {first-name}, {last-name}, {ORCiD}; ..."
  // otherwise, the list of users should not have ';' in it

  const { listUsers } = props;
  const splitUsers = listUsers.split('; ');
  return (
    <span>
      {splitUsers.map((u, index) => {
        const hasOrcid = ORCID_PATTERN.test(u);
        if (!hasOrcid) {
          return u;
        }

        const [name, surname, orcid] = u.split(', ');
        return (
          <span key={u}>
            {name} {surname}{' '}
            <a
              className={styles.orcid}
              href={`https://orcid.org/${orcid}`}
              target="_blank"
              rel="noreferrer"
            >
              <FaOrcid />
            </a>
            {index + 1 === splitUsers.length ? '' : <>&nbsp;,&nbsp;</>}
          </span>
        );
      })}
    </span>
  );
}

export default Users;
