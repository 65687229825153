import { useAllDatasets } from 'lib/api-hooks';
import type { ComponentType } from 'react';
import { useDeferredValue } from 'react';
import { useSearch } from 'wouter';

import type { ProcessedDataset } from '../api-models';
import { useScrollToTop } from '../hooks';
import { querySplitter } from '../Pagination/pagination';
import NoResults from './NoResults';
import Results from './Results';

interface DatasetCardProps {
  dataset: ProcessedDataset;
  sessionId: string;
}

interface CardProps {
  Card: ComponentType<DatasetCardProps>;
}

function SearchResults(props: CardProps) {
  useScrollToTop();
  const { Card } = props;

  // We use useDeferredValue to avoid suspending and hiding the pagination
  // on subsequent fetches - e.g. when the user navigates to the next page
  // of results.
  const searchParams = useSearch();
  const deferredSearchParams = useDeferredValue(searchParams);
  const isStale = deferredSearchParams !== searchParams;

  const query = new URLSearchParams(deferredSearchParams);

  const { paginationQuery, otherParams } = query
    ? querySplitter(Object.fromEntries(query))
    : { paginationQuery: undefined, otherParams: undefined };

  const filteredDatasets = useAllDatasets(otherParams, paginationQuery);

  const isEmpty = filteredDatasets.length === 0;

  if (isEmpty) {
    return <NoResults />;
  }
  return (
    <Results
      Card={Card}
      filteredDatasets={filteredDatasets}
      isStale={isStale}
    />
  );
}

export default SearchResults;
