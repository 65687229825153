import { useSuspenseQuery } from '@tanstack/react-query';

import {
  fetchAllDatasets,
  fetchDatasetsById,
  fetchDatasetsByQuery,
  fetchDOI,
  fetchFileList,
  fetchIcatSession,
  fetchInvestigationIds,
  fetchInvestigationParameters,
  fetchNeuroglancerInfo,
  fetchValuesParameters,
} from './api-fetchers';
import type {
  InvestigationParameter,
  PaginationFront,
  ProcessedDataset,
  ProcessedFileList,
  ValuesParameter,
} from './api-models';
import { QueryKey } from './api-models';
import { assertEnvVar } from './guards';

const DOMAIN_NEUROGLANCER = import.meta.env.VITE_NEUROGLANCER;
const PROJECT_NAME = import.meta.env.VITE_PROJECT_NAME;

export function useIcatSessionId(): string {
  return useSuspenseQuery({
    queryKey: [QueryKey.IcatSessionId],
    queryFn: fetchIcatSession,
    staleTime: Infinity,
    refetchInterval: 60 * 60 * 1000, // 60 min
  }).data;
}

export function useInvestigationIds(): string {
  const sessionId = useIcatSessionId();

  return useSuspenseQuery({
    queryKey: [QueryKey.InvestigationIds],
    queryFn: async () => fetchInvestigationIds(sessionId),
    staleTime: Infinity,
    refetchInterval: 60 * 60 * 1000, // 60 min
  }).data;
}

export function useAllDatasets(
  parametersInfo?: Record<string, string>,
  paginationQuery?: PaginationFront,
): ProcessedDataset[] {
  const sessionId = useIcatSessionId();
  const investigationIds = useInvestigationIds();

  return useSuspenseQuery({
    queryKey: [
      QueryKey.AllDatasets,
      sessionId,
      investigationIds,
      paginationQuery,
      parametersInfo,
    ],
    queryFn: async () =>
      fetchAllDatasets(
        sessionId,
        investigationIds,
        paginationQuery,
        parametersInfo,
      ),
    staleTime: Infinity,
  }).data;
}

export function useDatasetsById(datasetIds: string[]): ProcessedDataset[] {
  const sessionId = useIcatSessionId();

  return useSuspenseQuery({
    queryKey: [QueryKey.Datasets, sessionId, datasetIds],
    queryFn: async () => fetchDatasetsById(sessionId, datasetIds),
    staleTime: Infinity,
  }).data;
}

export function useDatasetsByQuery(queryParam: string): ProcessedDataset[] {
  const sessionId = useIcatSessionId();
  const investigationIds = useInvestigationIds();

  return useSuspenseQuery({
    queryKey: [QueryKey.Datasets, sessionId, investigationIds, queryParam],
    queryFn: async () =>
      fetchDatasetsByQuery(sessionId, investigationIds, queryParam),
    staleTime: Infinity,
  }).data;
}

export function useFileList(
  datasetId: string,
  excludeGallery = false,
  paginationQuery?: PaginationFront,
): ProcessedFileList {
  const sessionId = useIcatSessionId();

  return useSuspenseQuery({
    queryKey: [
      QueryKey.FileList,
      sessionId,
      datasetId,
      paginationQuery,
      excludeGallery,
    ],
    queryFn: async () =>
      fetchFileList(sessionId, datasetId, excludeGallery, paginationQuery),
    staleTime: Infinity,
  }).data;
}

export function useInvestigationParameters(
  queryParam: Record<string, string>,
): InvestigationParameter[] {
  const sessionId = useIcatSessionId();
  const investigationIds = useInvestigationIds();

  return useSuspenseQuery({
    queryKey: [
      QueryKey.InvestigationParameters,
      sessionId,
      investigationIds,
      queryParam,
    ],
    queryFn: async () =>
      fetchInvestigationParameters(sessionId, investigationIds, queryParam),
    staleTime: Infinity,
  }).data;
}

export function useValuesParameters(
  queryParam: Record<string, string>,
): ValuesParameter[] {
  const sessionId = useIcatSessionId();
  const investigationIds = useInvestigationIds();

  return useSuspenseQuery({
    queryKey: [
      QueryKey.ValuesParameters,
      sessionId,
      investigationIds,
      queryParam,
    ],
    queryFn: async () =>
      fetchValuesParameters(sessionId, investigationIds, queryParam),
    staleTime: Infinity,
  }).data;
}

export function useMetadataValues(name: string, param: string[] = []) {
  assertEnvVar(PROJECT_NAME, 'VITE_PROJECT_NAME');
  const params = [`project_name~eq~${PROJECT_NAME}`, ...param].join(',');
  return useValuesParameters({
    name,
    parameters: params,
  }).flatMap((p) => p.values);
}

export function useMultipleMetadataParams(
  names: Record<string, string>,
  queryParams: string,
): Record<string, string[] | undefined> {
  assertEnvVar(PROJECT_NAME, 'VITE_PROJECT_NAME');
  const results = useValuesParameters({
    name: Object.values(names).join(','),
    parameters: `project_name~eq~${PROJECT_NAME},${queryParams}`,
  });
  return Object.fromEntries(
    Object.keys(names).map((name) => {
      const values = results.find((p) => p.name === names[name])?.values || [];
      // Filter out potential empty strings in the fetched values of the metadata
      const nonEmptyValues = values.filter((v) => v.length > 0);
      // Returns undefined or an array with at least one non-empty string in it
      return [name, nonEmptyValues.length > 0 ? nonEmptyValues : undefined];
    }),
  );
}

export function useDOI(datasetId: number): string {
  const sessionId: string = useIcatSessionId();

  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.DOI, sessionId, datasetId],
    queryFn: async () => fetchDOI(sessionId, datasetId),
    staleTime: Infinity,
  });

  return data;
}

export function useNeuroglancerUrl(resourceId: string) {
  const sessionId: string = useIcatSessionId();
  assertEnvVar(DOMAIN_NEUROGLANCER, 'VITE_NEUROGLANCER');

  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.Neuroglancer, sessionId, resourceId],
    queryFn: async () => fetchNeuroglancerInfo(sessionId, resourceId),
    staleTime: Infinity,
  });

  const neuroInfos = encodeURIComponent(data);
  return `${DOMAIN_NEUROGLANCER}/#!${neuroInfos}`;
}
