import FilterRouting from '../Filters/FilterRouting';
import type { GroupFilter } from '../Filters/filters-models';
import styles from './SidebarFilters.module.css';

interface Props {
  filtersByGroup: GroupFilter[];
}

function SidebarFilters(props: Props) {
  const { filtersByGroup } = props;
  return (
    <div className={styles.filterSticky}>
      <div className={styles.filterContainer}>
        {filtersByGroup.map(({ groupLabel, filters }) => (
          <section className={styles.filterSection} key={groupLabel}>
            <h2 className={styles.filterName}>{groupLabel}</h2>
            <div className={styles.filter}>
              {filters.map((f) => (
                <FilterRouting key={f.name} {...f} />
              ))}
            </div>
          </section>
        ))}
      </div>
    </div>
  );
}

export default SidebarFilters;
