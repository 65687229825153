import type { GalleryFile, ProcessedDataset } from 'lib/api-models';
import { CitationExport, DOI, MetaItem, Users } from 'lib/dataset';
import { formatStringDate, getMetadataByName } from 'lib/helpers';
import type { PropsWithChildren } from 'react';

import styles from './Metadata.module.css';
import NeuroglancerWindow from './NeuroglancerWindows';

const { VITE_HIPCT_PAPER_DOI } = import.meta.env;

interface Props {
  isDesktop: boolean;
  doi: string;
  dataset: ProcessedDataset;
  neuroglancerFile: GalleryFile | undefined;
}

function Metadata(props: PropsWithChildren<Props>) {
  const { isDesktop, doi, dataset, neuroglancerFile, children } = props;
  const users = getMetadataByName(dataset.parameters, 'DOI_users');

  const scanDate = getMetadataByName(dataset.parameters, 'startDate');

  return (
    <>
      <div className={styles.metadataSection}>
        <div className={styles.headingMaster}>
          <h2 className={styles.headingTitle}>Description</h2>
          <p className={styles.headingAbstract}>
            {getMetadataByName(dataset.parameters, 'DOI_abstract')}
          </p>
        </div>
        <ul className={styles.metadataList}>
          <MetaItem label="Users">
            {users && <Users listUsers={users} />}
          </MetaItem>
          <MetaItem label="Technique">
            {getMetadataByName(dataset.parameters, 'TOMO_technique')}
          </MetaItem>
          <MetaItem label="Instrument">
            {dataset.instrumentName}, ESRF
            {/* {instrument.facility} */}
          </MetaItem>
          <MetaItem label="DOI">
            <DOI doi={doi} />
            <CitationExport doi={doi} name="DOI" />
          </MetaItem>
          <MetaItem label="Citation">
            Publications using this data <b>must cite the DOI</b> , and are
            encouraged to cite the{' '}
            <a
              className={styles.inlineLink}
              href="https://www.nature.com/articles/s41592-021-01317-x"
              target="_blank"
              rel="noreferrer"
            >
              HiP-CT methods paper
            </a>
            .
            <CitationExport doi={VITE_HIPCT_PAPER_DOI} name="paper" />
          </MetaItem>
        </ul>
      </div>

      {isDesktop && neuroglancerFile && (
        <NeuroglancerWindow resourceId={neuroglancerFile.id} />
      )}

      {children}

      <div className={styles.metadataSection}>
        <div className={styles.heading}>
          <h2 className={styles.headingTitle}>Donor</h2>
        </div>
        <ul className={styles.metadataList}>
          <MetaItem label="Number">
            {getMetadataByName(dataset.parameters, 'SamplePatient_number')}
          </MetaItem>
          <MetaItem label="Age">
            {getMetadataByName(dataset.parameters, 'SamplePatient_age')}
          </MetaItem>
          <MetaItem label="Sex">
            {getMetadataByName(dataset.parameters, 'SamplePatient_sex')}
          </MetaItem>
          <MetaItem label="Weight">
            {getMetadataByName(dataset.parameters, 'SamplePatient_weight')}
          </MetaItem>
          <MetaItem label="Height">
            {getMetadataByName(dataset.parameters, 'SamplePatient_size')}
          </MetaItem>
          <MetaItem label="Medical info">
            {getMetadataByName(dataset.parameters, 'SamplePatient_info')}
          </MetaItem>
        </ul>
      </div>
      <div className={styles.metadataSection}>
        <div className={styles.heading}>
          <h2 className={styles.headingTitle}>Sample</h2>
        </div>
        <ul className={styles.metadataList}>
          <MetaItem label="Name">
            {getMetadataByName(dataset.parameters, 'Sample_name')}
          </MetaItem>
          <MetaItem label="Organ">
            {getMetadataByName(dataset.parameters, 'SamplePatient_organ_name')}
          </MetaItem>
          <MetaItem label="Info">
            {getMetadataByName(dataset.parameters, 'Sample_description')}
          </MetaItem>
          <MetaItem label="Preparation">
            {getMetadataByName(
              dataset.parameters,
              'Sample_preparation_description',
            )}
          </MetaItem>
        </ul>
      </div>
      <div className={styles.metadataSection}>
        <div className={styles.heading}>
          <h2 className={styles.headingTitle}>Scan parameters</h2>
        </div>
        <ul className={styles.metadataList}>
          <MetaItem wide label="Scan date">
            {scanDate && formatStringDate(scanDate)}
          </MetaItem>
          <MetaItem wide label="SR current">
            {getMetadataByName(dataset.parameters, 'InstrumentSource_current')}
          </MetaItem>
          <MetaItem wide label="Beamline">
            {getMetadataByName(dataset.parameters, 'TOMO_idNames')}
          </MetaItem>
          <MetaItem wide label="Scan radix">
            {getMetadataByName(dataset.parameters, 'TOMO_scanRadix')}
          </MetaItem>
          <MetaItem wide label="X stages">
            {getMetadataByName(dataset.parameters, 'TOMO_xStages')}
          </MetaItem>
          <MetaItem wide label="Y step">
            {getMetadataByName(
              dataset.parameters,
              'TOMOAcquisition_flat_y_motion',
            )}
          </MetaItem>
          <MetaItem wide label="Y stages">
            {getMetadataByName(dataset.parameters, 'TOMO_yStages')}
          </MetaItem>
          <MetaItem wide label="Z step">
            {getMetadataByName(dataset.parameters, 'TOMOAcquisition_z_step')}
          </MetaItem>
          <MetaItem wide label="Z stages">
            {getMetadataByName(dataset.parameters, 'TOMO_zStages')}
          </MetaItem>
          <MetaItem wide label="Projections">
            {getMetadataByName(dataset.parameters, 'TOMOAcquisition_proj_n')}
          </MetaItem>
          <MetaItem wide label="Ref N">
            {getMetadataByName(dataset.parameters, 'TOMOAcquisition_flat_n')}
          </MetaItem>
          <MetaItem wide label="Dark N">
            {getMetadataByName(dataset.parameters, 'TOMOAcquisition_dark_n')}
          </MetaItem>
          <MetaItem wide label="Ref on">
            {getMetadataByName(dataset.parameters, 'TOMOAcquisition_flat_on')}
          </MetaItem>
          <MetaItem wide label="Scanning mode">
            {getMetadataByName(dataset.parameters, 'scanType')}
          </MetaItem>
          <MetaItem wide label="Exposure time">
            {getMetadataByName(
              dataset.parameters,
              'TOMOAcquisition_exposure_time',
            )}
          </MetaItem>
          <MetaItem wide label="Acc. exposure time">
            {getMetadataByName(
              dataset.parameters,
              'TOMOAcquisition_acc_exposure_time',
            )}
          </MetaItem>
          <MetaItem wide label="Acc. frames count">
            {getMetadataByName(
              dataset.parameters,
              'TOMOAcquisition_acc_frame_count',
            )}
          </MetaItem>
          <MetaItem wide label="Pixel size">
            {getMetadataByName(
              dataset.parameters,
              'TOMOAcquisition_sample_pixel_size',
            )}
          </MetaItem>
          <MetaItem wide label="Prop. distance">
            {getMetadataByName(
              dataset.parameters,
              'TOMOAcquisition_sample_detector_distance',
            )}
          </MetaItem>
          <MetaItem wide label="Filters">
            {getMetadataByName(
              dataset.parameters,
              'InstrumentAttenuator01_description',
            )}
          </MetaItem>
          <MetaItem wide label="Detected avg. energy">
            {getMetadataByName(dataset.parameters, 'TOMOAcquisition_energy')}
          </MetaItem>
          <MetaItem wide label="Scan geometry">
            {getMetadataByName(
              dataset.parameters,
              'TOMOAcquisition_half_acquisition',
            )}
          </MetaItem>
          <MetaItem wide label="Scan range">
            {getMetadataByName(
              dataset.parameters,
              'TOMOAcquisition_scan_range',
            )}
          </MetaItem>
          <MetaItem wide label="Sensor name">
            {getMetadataByName(
              dataset.parameters,
              'InstrumentDetector01_description',
            )}
          </MetaItem>
          <MetaItem wide label="Sensor mode">
            {getMetadataByName(
              dataset.parameters,
              'InstrumentDetector01_acquisition_mode',
            )}
          </MetaItem>
          <MetaItem wide label="Sensor pixel size">
            {getMetadataByName(
              dataset.parameters,
              'TOMOAcquisition_camera_pixel_size',
            )}
          </MetaItem>
          <MetaItem wide label="Magnification">
            {getMetadataByName(
              dataset.parameters,
              'TOMOAcquisition_magnification',
            )}
          </MetaItem>
          <MetaItem wide label="X pixel num.">
            {getMetadataByName(dataset.parameters, 'TOMO_x_pixel_n')}
          </MetaItem>
          <MetaItem wide label="Y pixel num.">
            {getMetadataByName(dataset.parameters, 'TOMO_y_pixel_n')}
          </MetaItem>
          <MetaItem wide label="Optics type">
            {getMetadataByName(
              dataset.parameters,
              'TOMOAcquisition_optic_type',
            )}
          </MetaItem>
          <MetaItem wide label="Scintillator">
            {getMetadataByName(
              dataset.parameters,
              'TOMOAcquisition_scintillator',
            )}
          </MetaItem>
          <MetaItem wide label="Surface dose rate">
            {getMetadataByName(dataset.parameters, 'TOMO_surface_dose')}
          </MetaItem>
          <MetaItem wide label="VOI dose rate">
            {getMetadataByName(dataset.parameters, 'TOMO_voi_dose')}
          </MetaItem>
          <MetaItem wide label="VOI integ. dose">
            {getMetadataByName(dataset.parameters, 'TOMO_total_voi_dose')}
          </MetaItem>
          <MetaItem wide label="Scan time">
            {getMetadataByName(dataset.parameters, 'TOMOAcquisition_duration')}
          </MetaItem>
          <MetaItem wide label="Series time">
            {getMetadataByName(
              dataset.parameters,
              'TOMOAcquisitionZseries_duration',
            )}
          </MetaItem>
        </ul>
      </div>
      <div className={styles.metadataSection}>
        <div className={styles.heading}>
          <h2 className={styles.headingTitle}>Processing</h2>
        </div>
        <ul className={styles.metadataList}>
          <MetaItem wide label="Ref. approach">
            {getMetadataByName(
              dataset.parameters,
              'TOMO_reference_description',
            )}
          </MetaItem>
          <MetaItem wide label="Volume X">
            {getMetadataByName(
              dataset.parameters,
              'TOMOReconstruction_nb_voxel_x',
            )}
          </MetaItem>
          <MetaItem wide label="Volume Y">
            {getMetadataByName(
              dataset.parameters,
              'TOMOReconstruction_nb_voxel_y',
            )}
          </MetaItem>
          <MetaItem wide label="Volume Z">
            {getMetadataByName(
              dataset.parameters,
              'TOMOReconstruction_nb_voxel_z',
            )}
          </MetaItem>
          <MetaItem wide label="32 to 16 bits min.">
            {getMetadataByName(dataset.parameters, 'TOMO_min32to16bits')}
          </MetaItem>
          <MetaItem wide label="32 to 16 bits max.">
            {getMetadataByName(dataset.parameters, 'TOMO_max32to16bits')}
          </MetaItem>
          <MetaItem wide label="JP2 compr. ratio">
            {getMetadataByName(dataset.parameters, 'TOMO_jp2CompressRatio')}
          </MetaItem>
        </ul>
      </div>
    </>
  );
}

export default Metadata;
