import { usePageTracking, useScrollToTop } from 'lib/hooks';
import { Helmet } from 'react-helmet';
import { Link, Route } from 'wouter';

import FijiTutorial from './Fiji/FijiTutorial';
import styles from './HelpPage.module.css';
import NeuroglancerTutorial from './NeuroglancerTutorial';

interface Props {
  isDesktop: boolean;
}

function HelpPage(props: Props) {
  useScrollToTop();
  usePageTracking();

  const { isDesktop } = props;

  return (
    <>
      <Helmet>
        <title>Help</title>
      </Helmet>

      <Route path="/fiji" nest>
        <FijiTutorial isDesktop={isDesktop} />
      </Route>

      <Route path="/neuroglancer">
        <NeuroglancerTutorial isDesktop={isDesktop} />
      </Route>

      <Route path="/">
        <div className={styles.wrapper}>
          <div className={styles.helpPart}>
            <h1 className={styles.title}>Tutorials</h1>
            <div className={styles.tutorialContainer}>
              <Link className={styles.tutorialCard} to="/neuroglancer">
                Neuroglancer
                <img
                  src="logos/neuroglancer.png"
                  alt="Logo of the Neuroglancer software"
                  width="200"
                  height="100"
                />
              </Link>
              <Link className={styles.tutorialCard} to="/fiji">
                Fiji
                <img
                  src="logos/logo_FIJI.png"
                  alt="Logo of the FIJI software"
                  width="100"
                  height="100"
                />
              </Link>
            </div>
          </div>
          <div className={styles.helpPart}>
            <h1 className={styles.title}>FAQ</h1>
            <div className={styles.faqContainer}>
              <details>
                <summary className={styles.sectionTitle}>
                  How do I download data?
                </summary>
                <p className={styles.sectionParagraph}>
                  Each dataset page shows list of files displayed like this:
                </p>
                <div>
                  <img
                    className={styles.image}
                    src="help/downloads.png"
                    alt="Screenshot of a list of download links as it may appear on a dataset page"
                  />
                </div>
                <p className={styles.sectionParagraph}>
                  You have two options for downloading the files:
                </p>
                <ul>
                  <li>
                    <h3 className={styles.subsectionTitle}>
                      Download individual files with HTTP (&lt; 2GB)
                    </h3>
                    <p className={styles.sectionParagraph}>
                      This is the simplest solution. Download the file you are
                      interested in by simply clicking on the name of the file.
                      This will start downloading the file in your web browser.
                      This is not reliable for big files and can fail after a
                      few gigabytes. A more reliable way to download large files
                      as well as multiple files at once is to use Globus (see
                      below).
                    </p>
                  </li>

                  <li>
                    <h3 className={styles.subsectionTitle}>
                      Download multiple files with Globus (&gt; 2GB)
                    </h3>
                    <p className={styles.sectionParagraph}>
                      This is the best option for downloading large datasets
                      reliably.
                    </p>
                    <ol className={styles.globusSteps}>
                      <li>
                        <a
                          className={styles.inlineLink}
                          href="https://www.globus.org/globus-connect-personal"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Install Globus Connect Personal
                        </a>
                      </li>
                      <li>
                        Start the software, and log in to create a{' '}
                        <a
                          className={styles.inlineLink}
                          href="https://docs.globus.org/faq/globus-connect-endpoints/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          local endpoint
                        </a>
                        .
                      </li>
                      <li>
                        In the search box, type "Human Organ Atlas" to find the
                        atlas' public endpoint:
                        <div>
                          <img
                            className={styles.image}
                            src="help/endpoint-results.png"
                            alt="Screenshot showing the Human Organ Atlas endpoint search result"
                          />
                        </div>
                      </li>
                      <li>
                        Click on the endpoint and then click on{' '}
                        <em>Open in File Manager</em>, you should see all the
                        datasets organised by donor and organ.
                      </li>
                      <li>
                        Select the dataset(s) you want to download, then select
                        the directory in which you want the data to be
                        downloaded and start the file transfer by clicking on
                        the <em>Start</em> button. Globus will restart the
                        transfer automatically in case of failure and inform you
                        once it is complete.
                      </li>
                    </ol>

                    <p className={styles.sectionParagraph}>
                      From the dataset web pages, you can go directly to the
                      dataset folder on Globus by clicking on the{' '}
                      <em>Download with Globus</em> button:
                    </p>
                    <div>
                      <img
                        className={styles.image}
                        src="help/globus-download.png"
                        alt="Screenshot showing a Globus download link on a dataset page"
                      />
                    </div>
                    <p className={styles.sectionParagraph}>
                      In case of problems with Globus, please email the ESRF
                      data managers at{' '}
                      <a
                        className={styles.inlineLink}
                        href="mailto:dataportalrequests@esrf.fr"
                        target="_blank"
                        rel="noreferrer"
                      >
                        dataportalrequests@esrf.fr
                      </a>
                      .
                    </p>
                  </li>
                </ul>
              </details>

              <details>
                <summary className={styles.sectionTitle}>
                  What if the downloaded data is corrupt ?
                </summary>
                <p className={styles.sectionParagraph}>
                  If you have downloaded a ZIP file and your operating system
                  cannot open it because it is corrupt, this is most likely due
                  to a failed download in a web browser. You can either try
                  downloading the file again, or use Globus as explained above
                  to try to download the ZIP file again in a more reliable way.
                </p>
              </details>

              <details>
                <summary className={styles.sectionTitle}>
                  What data format is the data?
                </summary>
                <p className={styles.sectionParagraph}>
                  The data are made available as{' '}
                  <a
                    className={styles.inlineLink}
                    href="https://jpeg.org/jpeg2000/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    JPEG2000
                  </a>{' '}
                  images.
                </p>
              </details>

              <details>
                <summary className={styles.sectionTitle}>
                  Under what license is data made available?
                </summary>
                <p className={styles.sectionParagraph}>
                  The data are made available under the{' '}
                  <a
                    className={styles.inlineLink}
                    href=" https://creativecommons.org/licenses/by/4.0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Creative Commons Attribution CC BY 4.0 license
                  </a>
                  . This means the datasets are accessible by all for re-use as
                  long as the DOI is cited when the data is used.
                </p>
              </details>

              <details>
                <summary className={styles.sectionTitle}>
                  How should I cite data?
                </summary>
                <p className={styles.sectionParagraph}>
                  Any publications using HOA data from must cite the dataset DOI
                  from every dataset used in the publication. The DOIs can be
                  found on the dataset web-pages.
                </p>
                <p className={styles.sectionParagraph} />
                We also encourage authors to cite other relevant papers, such as{' '}
                <a
                  className={styles.inlineLink}
                  href="https://doi.org/10.1038/s41592-021-01317-x"
                  target="_blank"
                  rel="noreferrer"
                >
                  the paper describing the experimental methods used to produce
                  these datasets.
                </a>
              </details>

              <details>
                <summary className={styles.sectionTitle}>
                  How do I search for data?
                </summary>
                <p className={styles.sectionParagraph}>
                  Click on the <em>Search</em> tab and select the filter
                  settings in the left-hand column you are interested in to find
                  the datasets which match the filters.
                </p>
              </details>

              <details>
                <summary className={styles.sectionTitle}>
                  Who can I contact if I have questions or problems?
                </summary>
                <ul>
                  <li>
                    For technical issues with downloading the data or using the
                    data portal, please email{' '}
                    <a
                      className={styles.inlineLink}
                      href="mailto:dataportalrequests@esrf.fr"
                      target="_blank"
                      rel="noreferrer"
                    >
                      dataportalrequests@esrf.fr
                    </a>
                    .
                  </li>
                  <li>
                    For questions about the data and how they were processed,
                    please contact David Stansby (
                    <a
                      className={styles.inlineLink}
                      href="mailto:d.stansby@ucl.ac.uk"
                      target="_blank"
                      rel="noreferrer"
                    >
                      d.stansby@ucl.ac.uk
                    </a>
                    ).
                  </li>
                </ul>
              </details>
            </div>
          </div>
        </div>
      </Route>
    </>
  );
}

export default HelpPage;
