import { useMetadataValues } from '../api-hooks';
import { capitalizeFirstLetter } from '../helpers';
import FilterBox from './FilterBox';
import styles from './Pickers.module.css';
import { OPERATORS, useQueryParam } from './router-utils';

interface Props {
  name: string;
  label: string | boolean;
}

function OptionsPicker(props: Props) {
  const { name, label } = props;
  const param = useQueryParam(name);

  const options = useMetadataValues(name);

  return (
    <FilterBox
      title={label}
      showTitle={label !== false}
      isActive={param.isActive}
      onClear={() => param.remove()}
    >
      <div className={styles.listOption}>
        {options.map((option) => (
          <label className={styles.option} key={option}>
            <input
              className={styles.inputOption}
              type="radio"
              value={option}
              checked={param.value === option}
              name={name}
              aria-label={option}
              onChange={() => param.setValue(`${OPERATORS.eq}${option}`)}
            />
            <div className={styles.labelOption}>
              {capitalizeFirstLetter(option)}
            </div>
          </label>
        ))}
      </div>
    </FilterBox>
  );
}

export default OptionsPicker;
