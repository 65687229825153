import { useMultipleMetadataParams } from 'lib/api-hooks';
import { assertDefined } from 'lib/guards';
import { useEffect, useRef } from 'react';
import { useParams } from 'wouter';

import OrganCard from './OrganCard';
import styles from './OrganList.module.css';

const NAMES = {
  sample: 'Sample_name',
};

function OrganList() {
  const { firstLayer } = useParams();
  assertDefined(firstLayer);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, [firstLayer]);

  const queryParam = `SamplePatient_number~eq~${firstLayer}`;
  const organUnique = useMultipleMetadataParams(NAMES, queryParam);

  return (
    <div ref={ref} className={styles.sampleContainer}>
      {organUnique.sample?.map((o) => <OrganCard key={o} organ={o} />)}
    </div>
  );
}

export default OrganList;
