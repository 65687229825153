import { useDatasetsByQuery, useIcatSessionId } from 'lib/api-hooks';
import styles from 'lib/Explore/DatasetList.module.css';
import { assertDefined } from 'lib/guards';
import { useEffect, useRef } from 'react';
import { useParams } from 'wouter';

import DatasetCard from '../Search/DatasetCard';

function DatasetList() {
  const { firstLayer, secondLayer } = useParams();
  assertDefined(firstLayer);
  assertDefined(secondLayer);

  const sessionId = useIcatSessionId();

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, [secondLayer]);

  const queryParam = `Sample_name~like~${firstLayer}_${secondLayer}`;
  const datasets = useDatasetsByQuery(queryParam);

  return (
    <div ref={ref} className={styles.datasetContainer}>
      {datasets.map((d) => (
        <DatasetCard key={d.id} dataset={d} sessionId={sessionId} />
      ))}
    </div>
  );
}

export default DatasetList;
