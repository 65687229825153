import { useMultipleMetadataParams } from 'lib/api-hooks';
import { capitalizeFirstLetter } from 'lib/helpers';
import { CATALOG_UNIT } from 'lib/unit-catalog';
import { FiUser } from 'react-icons/fi';
import { Link, useRoute } from 'wouter';

import styles from './DonorCard.module.css';

interface Props {
  donor: string;
}

const NAMES = {
  sex: 'SamplePatient_sex',
  age: 'SamplePatient_age',
  height: 'SamplePatient_size',
  weight: 'SamplePatient_weight',
  info: 'SamplePatient_info',
};

function DonorCard(props: Props) {
  const { donor } = props;

  const queryParam = `SamplePatient_number~eq~${donor}`;
  const donorInfos = useMultipleMetadataParams(NAMES, queryParam);

  const [isActive] = useRoute(`/${donor}/*?`);

  return (
    <Link
      className={styles.donorCard}
      data-active={isActive || undefined}
      to={`/${encodeURIComponent(donor)}`}
    >
      <div className={styles.cardTitle}>
        <div className={styles.fiUser}>
          <FiUser />
        </div>
        <h2 className={styles.donorTitle}>{donor}</h2>
      </div>
      <div className={styles.cardInfo}>
        {donorInfos.sex && (
          <div className={styles.infoPill}>
            {donorInfos.sex?.join('')} {CATALOG_UNIT[NAMES.sex]}
          </div>
        )}
        {donorInfos.age && (
          <div className={styles.infoPill}>
            {donorInfos.age?.join('')} {CATALOG_UNIT[NAMES.age]}
          </div>
        )}
        {donorInfos.weight && (
          <div className={styles.infoPill}>
            {donorInfos.weight?.join('')} {CATALOG_UNIT[NAMES.weight]}
          </div>
        )}
        {donorInfos.height && (
          <div className={styles.infoPill}>
            {donorInfos.height?.join('')} {CATALOG_UNIT[NAMES.height]}
          </div>
        )}
      </div>
      {donorInfos.info && (
        <p className={styles.donorAbstract}>
          {capitalizeFirstLetter(donorInfos.info?.join(''))}
        </p>
      )}
    </Link>
  );
}

export default DonorCard;
