import { useState } from 'react';
import { FiClipboard } from 'react-icons/fi';
import wretch from 'wretch';

import Spinner from '../Spinner';
import styles from './CitationExport.module.css';

interface Props {
  doi: string;
  name: string;
}
const CITATION_MIME_TYPES: Record<string, string> = {
  '.bib': 'application/x-bibtex',
  '.json': 'application/vnd.citationstyles.csl+json',
  '.ris': 'application/x-research-info-systems',
};

const EXTENSIONS = Object.keys(CITATION_MIME_TYPES);

function fetchCitation(doi: string, currentExtension: string) {
  return wretch(`https://dx.doi.org/${doi}`)
    .headers({
      Accept: `${CITATION_MIME_TYPES[currentExtension]}; charset=utf-8 `,
    })
    .get();
}

function CitationExport(props: Props) {
  const { doi, name } = props;
  const [currentExtension, setCiteExtension] = useState(EXTENSIONS[0]);
  const [doiFound, setDOIFound] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  async function handleClick() {
    try {
      setIsDownloading(true);
      const response = await fetchCitation(doi, `${currentExtension}`).blob();
      const blob = new Blob([response], { type: `text/plain` });
      const link = document.createElement(`a`);
      link.href = URL.createObjectURL(blob);
      link.download = `${doi}${currentExtension}`;
      link.click();
      URL.revokeObjectURL(link.href);
      setIsDownloading(false);
      setIsDownloaded(true);
      setTimeout(() => {
        setIsDownloaded(false);
      }, 500);
    } catch {
      setIsDownloaded(false);
      setDOIFound(false);
      setIsDownloading(false);
    }
  }

  async function copyDOI() {
    try {
      setIsCopying(true);
      const response = await fetchCitation(doi, `${currentExtension}`).text();
      await navigator.clipboard.writeText(response);
      setIsCopying(false);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 500);
    } catch {
      setIsCopied(false);
      setDOIFound(false);
      setIsCopying(false);
    }
  }

  return (
    <div className={styles.citeDOI}>
      <select
        className={styles.selectCitationType}
        onChange={(evt) => {
          setCiteExtension(evt.target.value);
        }}
      >
        {EXTENSIONS.map((citeExtensions) => (
          <option
            key={citeExtensions}
            value={citeExtensions}
            selected={citeExtensions === currentExtension}
          >
            {citeExtensions}
          </option>
        ))}
      </select>
      <button
        className={styles.copyButton}
        type="button"
        onClick={() => void handleClick()}
        data-copied={isDownloaded || undefined}
      >
        {isDownloading ? (
          <Spinner spinnerSize={9} />
        ) : (
          `Download ${name} citation as ${currentExtension}`
        )}
      </button>
      <button
        className={styles.copyButton}
        type="button"
        onClick={() => void copyDOI()}
        data-copied={isCopied || undefined}
      >
        {isCopying ? <Spinner spinnerSize={9} /> : `Copy ${currentExtension}`}

        <FiClipboard />
      </button>
      {!doiFound && (
        <div className={styles.errormessage}>
          {' '}
          Failed to fetch DOI metadata. Report the issue at{' '}
          <a
            className={styles.inlineLink}
            href="mailto:dataportalrequests@esrf.fr"
            target="_blank"
            rel="noreferrer"
          >
            dataportalrequests@esrf.fr
          </a>{' '}
          with the DOI number or try later.
        </div>
      )}
    </div>
  );
}

export default CitationExport;
