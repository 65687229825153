import { useDebouncedCallback } from '@react-hookz/web';
import { useState } from 'react';

import ClearButton from './ClearButton';
import FilterBox from './FilterBox';
import styles from './Input.module.css';
import { OPERATORS, useQueryParam } from './router-utils';

interface Props {
  name: string;
  label: string;
  placeholder?: string;
}

function TextInput(props: Props) {
  const { name, label, placeholder } = props;

  const param = useQueryParam(name);

  const [inputValue, setInputValue] = useState(param.value || '');

  const handleChange = useDebouncedCallback(
    (val: string) => param.setValue(`${OPERATORS.like}${val}`),
    [param],
    500,
  );

  return (
    <FilterBox title={label} showTitle={!!label} isActive={param.isActive}>
      <div className={styles.container}>
        <input
          className={styles.textInput}
          type="text"
          value={inputValue}
          placeholder={placeholder}
          aria-label={label}
          onChange={(evt) => {
            const { value } = evt.target;
            setInputValue(value);
            handleChange(value);
          }}
        />

        <ClearButton
          disabled={!inputValue}
          onClick={() => {
            setInputValue('');
            param.remove();
          }}
        />
      </div>
    </FilterBox>
  );
}

export default TextInput;
