import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import { useLocation } from 'wouter';

import type { Page } from '../api-models';
import { rangeArray, scrollToTop } from '../helpers';
import { useSearchParams } from '../hooks';
import Spinner from '../Spinner';
import { DEFAULT_PAGE_SIZE, SIZES } from './pagination';
import styles from './Pagination.module.css';

interface Props {
  paginationInfo: Page;
  loading: boolean;
  bottom?: boolean;
}

function Pagination(props: Props) {
  const { paginationInfo, loading, bottom } = props;
  const { currentPage, total, totalPages } = paginationInfo.page;
  const PAGES = rangeArray(totalPages);

  const query = useSearchParams();
  const pagSize = Number(query.get('size')) || DEFAULT_PAGE_SIZE;
  const maxRange = currentPage * pagSize;

  const [, navigate] = useLocation();

  function setSize(size: string) {
    if (bottom) {
      scrollToTop();
    }
    const params = new URLSearchParams(query);
    params.set('size', size);

    // If a user is on page 9 out of 10 with size 20 and choose a new size
    // of 50, the page 9 could not exist, creating an error
    // To avoid that, setting the page to '1' at every change of size
    if (params.has('page')) {
      params.set('page', '1');
    }
    const paramStr = params.toString();
    navigate(`?${paramStr}`);
  }

  function setPage(page: string) {
    if (bottom) {
      scrollToTop();
    }
    const params = new URLSearchParams(query);
    params.set('page', page);
    const paramStr = params.toString();
    navigate(`?${paramStr}`);
  }

  function handlePageChange(increment: number) {
    if (bottom) {
      scrollToTop();
    }
    const params = query;
    const page = Number.parseInt(params.get('page') || '1');
    const targetPage = Math.min(Math.max(page + increment, 1), totalPages);
    params.set('page', targetPage.toString());
    navigate(`?${params.toString()}`);
  }

  return (
    <div className={styles.paginationContainer}>
      <div className={styles.paginationNavigation}>
        <button
          className={styles.paginationButton}
          type="button"
          onClick={() => {
            setPage('1');
          }}
        >
          <FiChevronsLeft />
        </button>

        <button
          className={styles.paginationButton}
          type="button"
          onClick={() => {
            handlePageChange(-1);
          }}
        >
          <FiChevronLeft />
        </button>
        <div className={styles.limitContainers}>
          <div className={styles.limitContainer}>
            <span className={styles.text}>Show</span>
            <select
              className={styles.selectPagination}
              onChange={(evt) => {
                setSize(evt.target.value);
              }}
            >
              {SIZES.map((size) => (
                <option
                  key={size.label}
                  value={size.value}
                  selected={pagSize.toString() === size.value}
                >
                  {size.label}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.limitContainer}>
            <span className={styles.text}>Page</span>
            <select
              className={styles.selectPagination}
              onChange={(evt) => {
                setPage(evt.target.value);
              }}
            >
              {PAGES.map((page) => (
                <option key={page} value={page} selected={page === currentPage}>
                  {page}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button
          className={styles.paginationButton}
          type="button"
          onClick={() => {
            handlePageChange(1);
          }}
        >
          <FiChevronRight />
        </button>

        <button
          className={styles.paginationButton}
          type="button"
          onClick={() => {
            setPage(totalPages.toString());
          }}
        >
          <FiChevronsRight />
        </button>
      </div>

      <div className={styles.paginationResults}>
        {(currentPage - 1) * pagSize + 1}-{maxRange > total ? total : maxRange}{' '}
        of {total} results
      </div>

      {loading && <Spinner />}
    </div>
  );
}

export default Pagination;
