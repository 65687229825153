import { useDatasetsById, useDOI } from 'lib/api-hooks';
import { Files, Gallery } from 'lib/dataset';
import styles from 'lib/DatasetPage.module.css';
import { assertDefined } from 'lib/guards';
import {
  formatOrganDescrURL,
  getMetadataByName,
  trackDownload,
} from 'lib/helpers';
import { usePageTracking, useScrollToTop } from 'lib/hooks';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FiArrowLeft } from 'react-icons/fi';
import { Link } from 'wouter';

import { useCitationStore } from '../stores';
import Metadata from './Metadata';
import ModalDownload from './ModalDownload';

interface Props {
  datasetId: string;
  isDesktop: boolean;
}

function DatasetPage(props: Props) {
  useScrollToTop();
  usePageTracking();

  const modalStateInit = {
    open: false,
    url: '',
    ok: false,
    filename: '',
  };

  const { isDesktop, datasetId } = props;
  assertDefined(datasetId);

  const [dataset] = useDatasetsById([datasetId]);
  assertDefined(dataset);

  const doi = useDOI(dataset.id);

  const neuroglancerFile = dataset.gallery.find((file) =>
    file.name.includes('neuroglancer'),
  );

  const { modalSeen } = useCitationStore();
  const [modalState, setModalState] = useState(modalStateInit);

  function handleClick(evt: React.MouseEvent<HTMLAnchorElement>) {
    if (modalSeen) {
      trackDownload(evt);
      return;
    }

    evt.preventDefault();
    setModalState({
      ...modalState,
      open: true,
      url: evt.currentTarget.href,
      filename: evt.currentTarget.download,
    });
  }

  const donor = getMetadataByName(dataset.parameters, 'SamplePatient_number');
  const organ = getMetadataByName(
    dataset.parameters,
    'SamplePatient_organ_name',
  );
  assertDefined(donor);
  assertDefined(organ);

  const organPosition = getMetadataByName(
    dataset.parameters,
    'SamplePatient_organ_description',
  )?.split('_')[0];
  const otherDatasetsLink = organPosition
    ? formatOrganDescrURL(organ, organPosition)
    : organ;
  return (
    <>
      <Helmet>
        <title>{getMetadataByName(dataset.parameters, 'DOI_title')}</title>
      </Helmet>
      <div>
        <h1 className={styles.heading}>
          {getMetadataByName(dataset.parameters, 'DOI_title')}
        </h1>
        <Link
          className={styles.backToExplore}
          to={`/explore/${donor}/${otherDatasetsLink}`}
          state={{ previousId: datasetId }}
        >
          <FiArrowLeft className={styles.backArrow} />
          <span>View other datasets from this sample</span>
        </Link>
        <div className={styles.content}>
          <div className={styles.metadata}>
            <Metadata
              isDesktop={isDesktop}
              doi={doi}
              dataset={dataset}
              neuroglancerFile={neuroglancerFile}
            >
              {!isDesktop && (
                <div className={styles.scienceFiles}>
                  <Files
                    doi={doi}
                    datasetId={datasetId}
                    handleClick={handleClick}
                    excludeGallery
                    sortByFilesize
                  >
                    <ModalDownload
                      modalState={modalState}
                      setModalState={setModalState}
                    />
                  </Files>
                  <Gallery files={dataset.gallery} />
                </div>
              )}
            </Metadata>
          </div>
          {isDesktop && (
            <div className={styles.scienceFiles}>
              <Files
                doi={doi}
                datasetId={datasetId}
                handleClick={handleClick}
                excludeGallery
                sortByFilesize
              >
                <ModalDownload
                  modalState={modalState}
                  setModalState={setModalState}
                />
              </Files>
              <Gallery files={dataset.gallery} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DatasetPage;
