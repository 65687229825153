import { Boundary } from 'lib/boundary';
import type { ComponentType } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'wouter';

import type { ProcessedDataset } from '../api-models';
import type { GroupFilter } from '../Filters/filters-models';
import { useScrollToTop } from '../hooks';
import styles from './SearchLayout.module.css';
import SearchResults from './SearchResults';
import SidebarFilters from './SidebarFilters';

interface CardProps {
  dataset: ProcessedDataset;
  sessionId: string;
}

interface Props {
  isDesktop: boolean;
  Card: ComponentType<CardProps>;
  filtersByGroup: GroupFilter[];
}

function SearchLayout(props: Props) {
  useScrollToTop();
  const { isDesktop, Card, filtersByGroup } = props;
  const [location] = useLocation();

  return (
    <>
      <Helmet>
        <title>Search</title>
      </Helmet>

      <div className={styles.searchLayout}>
        {isDesktop ? (
          <aside className={styles.filtersAside}>
            <SidebarFilters filtersByGroup={filtersByGroup} />
          </aside>
        ) : (
          <details>
            <summary className={styles.filtersSummary}>Filters</summary>
            <SidebarFilters filtersByGroup={filtersByGroup} />
          </details>
        )}

        <Boundary resetKeys={[location]}>
          <SearchResults Card={Card} />
        </Boundary>
      </div>
    </>
  );
}

export default SearchLayout;
