import { useIcatSessionId, useMultipleMetadataParams } from 'lib/api-hooks';
import { assertDefined, assertEnvVar } from 'lib/guards';
import {
  capitalizeFirstLetter,
  formatOrganDescr,
  formatOrganDescrURL,
  sampleNameSplitter,
} from 'lib/helpers';
import { Link, useRoute } from 'wouter';

import styles from './OrganCard.module.css';

interface Props {
  organ: string;
}

const ICATP = import.meta.env.VITE_ICATP;

const NAMES = {
  galleryFilePaths: 'ResourcesGalleryFilePaths',
  galleryFileIds: 'ResourcesGallery',
};

function OrganCard(props: Props) {
  assertEnvVar(ICATP, 'VITE_ICATP');
  const { organ } = props;

  const sessionId = useIcatSessionId();

  const queryParam = `Sample_name~eq~${organ}`;
  const organInfo = useMultipleMetadataParams(NAMES, queryParam);

  const { galleryFilePaths } = organInfo;
  assertDefined(galleryFilePaths);
  const resources = galleryFilePaths[0].split(',');

  const { galleryFileIds } = organInfo;
  assertDefined(galleryFileIds);
  const resourcesId = galleryFileIds[0].split(' ');

  const sampleIndex = resources.findIndex(
    // Remove the .tif filter when the files are deleted
    (r) => r.includes('sample_') && !r.endsWith('.tif'),
  );
  const thumbnailId = resourcesId[sampleIndex];

  const { organName, position } = sampleNameSplitter(organ);
  const sampleDescr = position
    ? formatOrganDescr(organName, position)
    : organName;
  const sampleDescrURL = position
    ? formatOrganDescrURL(organName, position)
    : organName;

  const [isActive] = useRoute(`/${sampleDescrURL}`);

  return (
    <Link
      to={`/${encodeURIComponent(sampleDescrURL)}`}
      className={isActive ? styles.activeOrganCard : styles.organCard}
    >
      <h2 className={styles.organCardTitle}>
        {capitalizeFirstLetter(sampleDescr)}
      </h2>
      {thumbnailId && (
        <div className={styles.thumbnail}>
          <img
            className={styles.organThumbnail}
            alt="3D rendering of the sample"
            src={`${ICATP}/resource/${sessionId}/file/download?resourceId=${thumbnailId}`}
          />
        </div>
      )}
    </Link>
  );
}

export default OrganCard;
