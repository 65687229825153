export const CATALOG_UNIT: Record<string, string> = {
  SamplePatient_age: 'yo',
  SamplePatient_weight: 'kg',
  SamplePatient_size: 'cm',
  InstrumentSource_current: 'mA',
  TOMOAcquisition_z_step: 'mm',
  TOMOAcquisition_exposure_time: 's',
  TOMOAcquisition_acc_exposure_time: 's',
  TOMOAcquisition_sample_pixel_size: 'um',
  TOMOAcquisition_sample_detector_distance: 'um',
  TOMOAcquisition_energy: 'keV',
  TOMOAcquisition_scan_range: 'deg',
  TOMOAcquisition_camera_pixel_size: 'um',
  TOMO_surface_dose: 'Gy/s',
  TOMO_voi_dose: 'Gy/s',
  TOMO_total_voi_dose: 'kGy',
  TOMOAcquisition_duration: 'min',
  TOMOAcquisitionZseries_duration: 'h',
};
