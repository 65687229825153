import PulseLoader from 'react-spinners/PulseLoader';

import styles from './Spinner.module.css';

interface Props {
  spinnerSize?: number;
}
function Spinner(props: Props) {
  const { spinnerSize = 15 } = props;
  return (
    <div className={styles.spinner}>
      <PulseLoader color="currentColor" size={spinnerSize} />
    </div>
  );
}

export default Spinner;
